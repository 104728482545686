@font-face {
    font-family: 'Veneer';
    src: url('Veneer.eot');
    src: url('Veneer.eot?#iefix') format('embedded-opentype'),
        url('Veneer.woff2') format('woff2'),
        url('Veneer.woff') format('woff'),
        url('Veneer.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

