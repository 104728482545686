@import "../assets/fonts/veneer/stylesheet.css";

* {
  cursor: url("../assets/images/cursor.png") 15 15, auto;
}

*:hover {
  cursor: url("../assets/images/cursor.png") 15 15, auto !important;
}

html {
  width: 100%;
  scroll-padding-top: 0vh !important;
  @apply overflow-x-hidden bg-dark;
}

@media (min-width: 1024px) {
  html {
    scroll-padding-top: 7vh !important;
    margin-top: 0;
  }
}

/* @media (min-width: 1024px) {
  *,
  body,
  html {
    cursor: none;
  }

  button {
    cursor: none !important;
  }
} */

#Page-1 {
  display: none;
}

.bit-play-my-city-wrapper {
  display: none !important;
}

.bit-rsvp-container,
.bit-offers-inner-wrapper {
  margin: 0 !important;
}

.bit-rsvp-container {
  display: none !important;
}

.bit-widget,
.bit-mobile-date,
.bit-follow-section-heading-text,
.bit-event-list-title {
  font-size: 1.4vw !important;
  line-height: 100%;
}

.bit-details-inner-wrapper {
  margin-top: 2vh;
}

.bit-titleWrapper {
  margin-top: 1vh;
}

@media (max-width: 768px) {
  .bit-widget,
  .bit-mobile-date,
  .bit-follow-section-heading-text,
  .bit-event-list-title {
    font-size: 5vw !important;
    line-height: 120%;
  }
}
